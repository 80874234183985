import { mapState } from 'vuex';

export default {
	name: 'ProjectDeadline',
	props: {
		projectDeadline: {
			type: String,
		},
		startDate: {
			type: String,
		},
		endDate: {
			type: String,
		},
		isDateRange: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		// Get projectId, userId from store
		...mapState(['projectId', 'userId', 'projectInfo', 'clientInfo', 'schedule']),
	},
};
